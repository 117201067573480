<template>
	<div>
		<div class="navbar-brand">
			<a :href="mainUrl" v-if="loginUserId != ''">
				<img src="/images/a-safe-logo.png" alt="" style="height: 68px; image-rendering: -webkit-optimize-contrast" />
			</a>
			<!-- <a href="/main" v-if="loginUserId != '' && menuGubun == '02'">
				<img src="/images/a-safe-logo.png" alt="" style="height: 68px; image-rendering: -webkit-optimize-contrast" />
			</a> -->
			<a href="/" v-if="loginUserId == ''">
				<img src="/images/a-safe-logo.png" alt="" style="height: 68px; image-rendering: -webkit-optimize-contrast" />
			</a>
		</div>
		<div id="topmenu" style="clear: both">
			<div class="navbar navbar-inverse" style="border: none; height: 48px">
				<!-- 모바일 용 메뉴 (화면 가로폭이 좁아졌을 때에만 보임) -->
				<div class="navbar-collapse collapse" id="navbar-mobile" style="height: 48px !important">
					<div class="nav navbar-nav navbar-menu" style="margin-left: 40px">
						<li>
							<i class="icon-paragraph-justify3"></i>
						</li>
						<!-- 건설메뉴 -->
						<ul v-if="menuGubun == '02'">
							<!-- <li>
								<a href="/main">
									<span>홈</span>
								</a>
							</li> -->
							<li v-if="hasAuthority(['/viewAccidentPrevent.do'])">
								<a @click="loadView('accidentPrevent', $event)" data-desc="재해예방조치">
									<span>재해예방조치</span>
								</a>
							</li>
							<li v-if="hasAuthority(['/viewDangerOccur.do', '/viewWarnWorkOccur.do'])">
								<a href="#">
									<span>위험/주의발생이력</span>
								</a>
								<div class="hidden-ul">
									<ul>
										<li v-if="hasAuthority(['/viewDangerOccur.do'])">
											<a @click="loadView('dangerOccur', $event)" data-desc="위험발생이력">
												<span>위험발생이력</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewDangerOccur.do'])">
											<a @click="loadView('dangerOccurVideo', $event)" data-desc="위험발생이력">
												<span>위험발생이력-비디오</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewWarnWorkOccur.do'])">
											<a @click="loadView('warnWorkOccur', $event)" data-desc="주의작업이력">
												<span>주의작업이력</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewWarnWorkOccur.do'])">
											<a @click="loadView('warnWorkOccurVideo', $event)" data-desc="주의작업이력">
												<span>주의작업이력-비디오</span>
											</a>
										</li>
									</ul>
								</div>
							</li>
							<li v-if="hasAuthority(['/viewTree.do', '/viewSiteStatistic.do', '/viewAccidentStatistic.do', '/viewObjectDangerOccurStatistic.do'])">
								<a href="#">
									<span>조회/통계</span>
								</a>
								<div class="hidden-ul">
									<ul>
										<li v-if="hasAuthority(['/viewTree.do'])">
											<a @click="loadView('tree', $event)" data-desc="현장별사용자조회">
												<span>현장별 사용자 조회</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewSiteStatistic.do'])">
											<a @click="loadView('siteStatistic', $event)" data-desc="현장별통계">
												<span>현장별통계</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewAccidentStatistic.do'])">
											<a @click="loadView('accidentStatistic', $event)" data-desc="재해유형별통계">
												<span>재해유형별통계</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewObjectDangerOccurStatistic.do'])">
											<a @click="loadView('objectDangerOccurStatistic', $event)" data-desc="대상물별위험통계">
												<span>대상물별위험통계</span>
											</a>
										</li>
									</ul>
								</div>
							</li>
							<li v-if="hasAuthority(['/viewExemplaryCase.do'])">
								<a href="/exemplary-case" data-desc="모범사례">
									<span>모범사례</span>
								</a>
							</li>
							<!-- <li
								v-if="
									hasAuthority([
										'/viewNotice.do',
										'/viewCompany.do',
										'/viewSite.do',
										'/viewRoleCode.do',
										'/viewRoleGroup.do',
										'/viewUser.do',
										'/viewGrpCode.do',
										'/viewCommCode.do',
										'/viewObject.do',
										'/viewTarget.do',
										'/viewObjectTarget.do',
										'/viewApiCallErrLog.do',
										'/viewCctvDangerAreaConfig.do',
									])
								"
							> -->
							<li v-if="hasAuthority(['/viewNotice.do', '/viewCompany.do', '/viewSite.do', '/viewUser.do', '/viewMenu.do', '/viewRoleGroup.do'])">
								<a href="#">
									<span>조직관리</span>
								</a>
								<div class="hidden-ul">
									<ul>
										<li v-if="hasAuthority(['/viewNotice.do'])">
											<a href="/notice" data-desc="공지사항">
												<span>공지사항</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewCompany.do'])">
											<a href="/company" data-desc="업체관리">
												<span>업체관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewSite.do'])">
											<a @click="loadView('site', $event)" data-desc="현장관리">
												<span>현장관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewUser.do'])">
											<a @click="loadView('user', $event)" data-desc="사용자관리">
												<span>사용자관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewMenu.do'])">
											<a @click="loadView('menu', $event)" data-desc="메뉴코드관리">
												<span>메뉴코드관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewRoleGroup.do'])">
											<a @click="loadView('roleGroup', $event)" data-desc="권한그룹관리">
												<span>권한그룹관리</span>
											</a>
										</li>
									</ul>
								</div>
							</li>
							<li
								v-if="
									hasAuthority([
										'/viewGrpCode.do',
										'/viewCommCode.do',
										'/viewObject.do',
										'/viewTarget.do',
										'/viewObjectTarget.do',
										'/viewWarnWork.do',
										'/viewCctv.do',
										'/viewBasicAiConfig.do',
										'/viewCctvAiConfig.do',
										'/viewApiCallErrLog.do',
										'/viewCctvDangerAreaConfig.do',
									])
								"
							>
								<a href="#">
									<span>AI/데이터관리</span>
								</a>
								<div class="hidden-ul">
									<ul>
										<li v-if="hasAuthority(['/viewGrpCode.do'])">
											<a href="/group-code" data-desc="그룹코드관리">
												<span>그룹코드관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewCommCode.do'])">
											<a @click="loadView('commCode', $event)" data-desc="공통코드관리">
												<span>공통코드관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewObject.do'])">
											<a @click="loadView('object', $event)" data-desc="대상물관리">
												<span>대상물관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewTarget.do'])">
											<a href="/target" data-desc="조치대상관리">
												<span>조치대상관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewObjectTarget.do'])">
											<a @click="loadView('objectTarget', $event)" data-desc="대상물조치대상관리">
												<span>대상물조치대상관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewWarnWork.do'])">
											<a @click="loadView('warnWork', $event)" data-desc="위험대상물조합관리">
												<span>위험대상물조합관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewCctv.do'])">
											<a @click="loadView('cctv', $event)" data-desc="CCTV관리">
												<span>CCTV관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewCctvAiConfig.do'])">
											<a @click="loadView('cctvAiConfig', $event)" data-desc="AICCTV설정">
												<span>AICCTV설정</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewBasicAiConfig.do'])">
											<a @click="loadView('basicAiConfig', $event)" data-desc="AI기본설정">
												<span>AI기본설정</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewApiCallErrLog.do'])">
											<a @click="loadView('apiCallErrLog', $event)" data-desc="API호출오류로그조회">
												<span>API호출오류로그조회</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewCctvDangerAreaConfig.do'])">
											<a @click="loadView('cctvDangerAreaConfig', $event)" data-desc="CCTV 위험영역 설정">
												<span>CCTV위험지역설정</span>
											</a>
										</li>
									</ul>
								</div>
							</li>
							<li>
								<a @click="loadView('dashboard', $event)" data-desc="dashboard" target="_blank">
									<span>모니터링</span>
								</a>
								<!-- <a href="/dashboard" data-desc="대시보드">
									<span>대시보드</span>
								</a> -->
							</li>
						</ul>
						<!-- 현대제철용 메뉴 -->
						<ul v-if="menuGubun == '01'">
							<li v-if="hasAuthority(['/viewDangerOccur.do', '/viewWarnWorkOccur.do'])">
								<a href="#">
									<span>위험발생이력</span>
								</a>
								<div class="hidden-ul">
									<ul>
										<li v-if="hasAuthority(['/viewDangerOccur.do'])">
											<a @click="loadView('dangerOccur', $event)" data-desc="위험발생이력">
												<span>위험발생이력</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewDangerOccur.do'])">
											<a @click="loadView('dangerOccurVideo', $event)" data-desc="위험발생이력">
												<span>위험발생이력-비디오</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewWarnWorkOccur.do'])">
											<a @click="loadView('warnWorkOccur', $event)" data-desc="주의작업이력">
												<span>주의작업이력</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewWarnWorkOccur.do'])">
											<a @click="loadView('warnWorkOccurVideo', $event)" data-desc="주의작업이력">
												<span>주의작업이력-비디오</span>
											</a>
										</li>
									</ul>
								</div>
							</li>
							<li v-if="hasAuthority(['/viewTree.do', '/viewObjectDangerOccurStatistic.do'])">
								<a href="#">
									<span>조회/통계</span>
								</a>
								<div class="hidden-ul">
									<ul>
										<li v-if="hasAuthority(['/viewTree.do'])">
											<a @click="loadView('tree', $event)" data-desc="현장별사용자조회">
												<span>현장별 사용자 조회</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewObjectDangerOccurStatistic.do'])">
											<a @click="loadView('objectDangerOccurStatistic', $event)" data-desc="대상물별위험통계">
												<span>대상물별위험통계</span>
											</a>
										</li>
									</ul>
								</div>
							</li>
							<li v-if="hasAuthority(['/viewNotice.do', '/viewCompany.do', '/viewSite.do', '/viewUser.do', '/viewMenu.do', '/viewRoleGroup.do'])">
								<a href="#">
									<span>조직관리</span>
								</a>
								<div class="hidden-ul">
									<ul>
										<li v-if="hasAuthority(['/viewNotice.do'])">
											<a href="/notice" data-desc="공지사항">
												<span>공지사항</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewCompany.do'])">
											<a href="/company" data-desc="업체관리">
												<span>업체관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewSite.do'])">
											<a @click="loadView('site', $event)" data-desc="현장관리">
												<span>현장관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewUser.do'])">
											<a @click="loadView('user', $event)" data-desc="사용자관리">
												<span>사용자관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewMenu.do'])">
											<a @click="loadView('menu', $event)" data-desc="메뉴코드관리">
												<span>메뉴코드관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewRoleGroup.do'])">
											<a @click="loadView('roleGroup', $event)" data-desc="권한그룹관리">
												<span>권한그룹관리</span>
											</a>
										</li>
									</ul>
								</div>
							</li>
							<li
								v-if="
									hasAuthority([
										'/viewGrpCode.do',
										'/viewCommCode.do',
										'/viewObject.do',
										'/viewTarget.do',
										'/viewObjectTarget.do',
										'/viewWarnWork.do',
										'/viewCctv.do',
										'/viewBasicAiConfig.do',
										'/viewCctvAiConfig.do',
										'/viewApiCallErrLog.do',
										'/viewCctvDangerAreaConfig.do',
									])
								"
							>
								<a href="#">
									<span>AI/데이터관리</span>
								</a>
								<div class="hidden-ul">
									<ul>
										<li v-if="hasAuthority(['/viewGrpCode.do'])">
											<a href="/group-code" data-desc="그룹코드관리">
												<span>그룹코드관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewCommCode.do'])">
											<a @click="loadView('commCode', $event)" data-desc="공통코드관리">
												<span>공통코드관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewObject.do'])">
											<a @click="loadView('object', $event)" data-desc="대상물관리">
												<span>대상물관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewTarget.do'])">
											<a href="/target" data-desc="조치대상관리">
												<span>조치대상관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewObjectTarget.do'])">
											<a @click="loadView('objectTarget', $event)" data-desc="대상물조치대상관리">
												<span>대상물조치대상관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewWarnWork.do'])">
											<a @click="loadView('warnWork', $event)" data-desc="위험대상물조합관리">
												<span>위험대상물조합관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewCctv.do'])">
											<a @click="loadView('cctv', $event)" data-desc="CCTV관리">
												<span>CCTV관리</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewCctvAiConfig.do'])">
											<a @click="loadView('cctvAiConfig', $event)" data-desc="AICCTV설정">
												<span>AICCTV설정</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewBasicAiConfig.do'])">
											<a @click="loadView('basicAiConfig', $event)" data-desc="AI기본설정">
												<span>AI기본설정</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewApiCallErrLog.do'])">
											<a @click="loadView('apiCallErrLog', $event)" data-desc="API호출오류로그조회">
												<span>API호출오류로그조회</span>
											</a>
										</li>
										<li v-if="hasAuthority(['/viewCctvDangerAreaConfig.do'])">
											<a @click="loadView('cctvDangerAreaConfig', $event)" data-desc="CCTV 위험영역 설정">
												<span>CCTV위험지역설정</span>
											</a>
										</li>
									</ul>
								</div>
							</li>
							<li>
								<a @click="loadView('dashboard', $event)" data-desc="dashboard" target="_blank">
									<span>모니터링</span>
								</a>
								<!-- <a href="/dashboard" data-desc="대시보드">
									<span>대시보드</span>
								</a> -->
							</li>
						</ul>
					</div>
					<ul class="nav navbar-nav navbar-right" style="height: 48px">
						<li class="dropdown dropdown-user" v-if="loginUserId != ''">
							<a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
								<i class="icon-user-tie"></i>
								<span>{{ loginUserId }}</span>
								<i class="caret"></i>
							</a>
							<ul class="dropdown-menu dropdown-menu-right">
								<!-- <li>
									<a data-toggle="modal" data-target="#pw_change_popup">
										<i class="icon-user-check"></i>
										비밀번호 변경
									</a>
								</li>
								<li class="divider"></li> -->
								<li>
									<a @click="logout">
										<i class="icon-switch2"></i>
										로그아웃
									</a>
								</li>
							</ul>
						</li>
						<li class="dropdown dropdown-user" v-else>
							<a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
								<i class="icon-user-tie"></i>
								<span>Anonymous</span>
								<i class="caret"></i>
							</a>
							<ul class="dropdown-menu dropdown-menu-right">
								<!-- <li class="divider"></li> -->
								<li>
									<a href="/login">
										<i class="icon-switch2"></i>
										로그인
									</a>
								</li>
							</ul>
						</li>
					</ul>
				</div>
				<!-- /모바일 용 메뉴 (화면 가로폭이 좁아졌을 때에만 보임) -->
			</div>

			<!-- 비밀번호 변경 팝업 -->
			<!-- <div id="pw_change_popup" class="modal fade">
		<div class="modal-dialog modal-md">
			<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal">&times;</button>
				<h5 class="modal-title">비밀번호 변경</h5>
			</div>
			<form action="#" class="form-horizontal">
				<div class="modal-body">
				<div class="form-group">
					<label class="control-label col-sm-5">현재 비밀번호</label>
					<div class="col-sm-7">
					<input type="password" :placeholder="fieldMap.password" class="form-control" v-model="password"
						data-vv-name="fieldMap.password" v-validate="validationRule.password" />
					<div class="form-control-feedback" style="right: 10px;"><i class="icon-lock text-muted"></i></div>
					<able-field-error field="fieldMap.password"></able-field-error>
					</div>
				</div>

				<div class="form-group">
					<label class="control-label col-sm-5">새 비밀번호</label>
					<div class="col-sm-7">
					<input type="password" name="newPassword" ref="newPassword" :placeholder="fieldMap.newPassword" class="form-control" v-model="newPassword"
						data-vv-name="fieldMap.newPassword" v-validate="validationRule.newPassword" />
					<div class="form-control-feedback" style="right: 10px;"><i class="icon-lock text-muted"></i></div>
					<able-field-error field="fieldMap.newPassword"></able-field-error>
					</div>
				</div>

				<div class="form-group">
					<label class="control-label col-sm-5">새 비밀번호 확인</label>
					<div class="col-sm-7">
					<input type="password" :placeholder="fieldMap.newPasswordRe" class="form-control" v-model="newPasswordRe"
						data-vv-name="fieldMap.newPasswordRe" v-validate="validationRule.newPasswordRe" />
					<div class="form-control-feedback" style="right: 10px;"><i class="icon-lock text-muted"></i></div>
					<able-field-error field="fieldMap.newPasswordRe"></able-field-error>
					</div>
				</div>
				</div>
				<div class="modal-footer">
				<button type="button" class="btn btn-link" data-dismiss="modal">닫기</button>
				<button type="button" class="btn btn-primary" @click="changePassword">비밀번호 변경</button>
				</div>
			</form>
			</div>
		</div>
		</div> -->
			<!-- //비밀번호 변경 팝업 -->
		</div>
	</div>
	<!-- /main navbar -->
</template>

<style scoped>
.navbar-menu > ul li {
	list-style: none;
	float: left;
	/* width: 170px; */
	width: 150px;
	position: relative;
}
.navbar-menu > ul li > a {
	color: #fff;
	display: block;
	/* width: 170px; */
	width: 150px;
	height: 48px;
	padding-top: 1px;
}
.navbar-menu {
	width: 1200px;
	line-height: 48px;
	margin: 0 auto;
	text-align: center;
}
.navbar-menu > ul > li > .hidden-ul {
	/* width: 170px; */
	width: 150px;
	max-height: 0px;
	overflow: hidden;
	position: absolute;
	background: #263238;
	text-align: center;
	z-index: 1;
	transition: max-height 0.3s;
}
.navbar-menu > ul > li:hover > .hidden-ul {
	max-height: 850px;
	z-index: 1;
	transition: max-height 0.3s;
}
.hidden-ul > ul > li:hover > a {
	font-weight: bold;
	background: #1d272b;
}
.navbar-menu > ul > li {
	background: #37474f;
	transition: all 0.3s;
}
.navbar-menu > ul > li:hover {
	background: #314047;
}
.hidden-ul > ul > li > a {
	display: block;
	/* width: 170px; */
	width: 150px;
	margin-left: -25px;
}
</style>
<script>
import jwt from 'jsonwebtoken';
import VueCookies from 'vue-cookies';
import apiIndex from '../../api/index';

let axiosExtention;

export default {
	data: () => ({
		loginUserNm: '',
		loginUserId: '',
		loginUserMenuList: [],
		menuGubun: '',
		mainUrl: '/',
	}),
	created() {
		axiosExtention = this.$jarvisExtention.axiosExtention;
		this.getCommCodeList();
		this.getLoginUser();
	},
	mounted() {
		this.menuGubun = process.env.VUE_APP_MENU_GUBUN;
		this.mainUrl = apiIndex.mainUrl;
	},
	methods: {
		getLoginUser() {
			// jwt 토큰에서 로그인 유저 아이디 가져오기
			let accessToken = VueCookies.get('V2_ACCESS_TOKEN');
			let decodedAccessToken = jwt.decode(accessToken);
			this.loginUserId = decodedAccessToken.userId;
			this.loginUserMenuList = JSON.parse(decodedAccessToken.userMenu);
			sessionStorage.setItem('loginUserInfo', JSON.stringify(decodedAccessToken));
		},
		async loadView(name, e) {
			// name: 연결 Url 혹은 name / e: click Event 정보(e는 필요하면 사용 - 사용 안하면 지워주세요)
			// ex) name: exemplaryCase(모범사례 - 목록) name에 따라
			// if / switch로 분기하여 정보 조회 url, 연결 화면 url 을 지정하여 사용
			// 또는 @click마다 function 호출 다르게 적용
			console.log('loadView', 'name', name, 'e', e, 'e.target', e.target);

			let url = '';
			if (name == 'accidentPrevent') url = apiIndex.accidentPrevent.viewAccidentPrevent;
			else if (name == 'warnWorkOccur') url = apiIndex.warnWorkOccur.viewWarnWorkOccur;
			else if (name == 'warnWorkOccurVideo') url = apiIndex.warnWorkOccur.viewWarnWorkOccurVideo;
			else if (name == 'dangerOccur') url = apiIndex.dangerOccur.viewDangerOccur;
			else if (name == 'dangerOccurVideo') url = apiIndex.dangerOccur.viewDangerOccurVideo;
			else if (name == 'siteStatistic') url = apiIndex.siteStatistic.viewSiteStatistic;
			else if (name == 'accidentStatistic') url = apiIndex.accidentStatistic.viewAccidentStatistic;
			else if (name == 'site') url = apiIndex.site.viewSite;
			else if (name == 'user') url = apiIndex.user.viewUser;
			else if (name == 'menu') url = apiIndex.menu.viewMenu;
			else if (name == 'roleGroup') url = apiIndex.roleGroup.viewRoleGroup;
			else if (name == 'commCode') url = apiIndex.commCode.viewCommCode;
			else if (name == 'object') url = apiIndex.object.viewObject;
			else if (name == 'objectTarget') url = apiIndex.objectTarget.viewObjectTarget;
			else if (name == 'warnWork') url = apiIndex.warnWork.viewWarnWork;
			else if (name == 'cctv') url = apiIndex.cctv.viewCctv;
			else if (name == 'basicAiConfig') url = apiIndex.basicAiConfig.viewBasicAiConfig;
			else if (name == 'cctvAiConfig') url = apiIndex.cctvAiConfig.viewCctvAiConfig;
			else if (name == 'apiCallErrLog') url = apiIndex.apiCallErrLog.viewApiCallErrLog;
			else if (name == 'cctvDangerAreaConfig') url = apiIndex.cctvDangerAreaConfig.viewCctvDangerAreaConfig;
			else if (name == 'objectDangerOccurStatistic') url = apiIndex.objectDangerOccurStatistic.viewObjectDangerOccurStatistic;
			else if (name == 'tree') url = apiIndex.tree.viewTree;
			else if (name == 'dashboard') url = apiIndex.dashboard.viewDashboard;
			// else if (name == 'exemplaryCase') url = apiIndex.exemplaryCase.viewExemplaryCase;

			// topmenu에서 page이동 시 commonCodeList가 존재하지 않으면 commonCodeList 로드
			const commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));
			const loginUserInfo = JSON.parse(sessionStorage.getItem('loginUserInfo'));

			if (!commonCodeList || commonCodeList.length == 0) {
				await this.getCommCodeList();
			}

			if (!loginUserInfo || loginUserInfo.length == 0) {
				this.getLoginUser();
			}

			await this.$axios
				.post(url, {})
				.then(
					function (r) {
						if (r.data) {
							let data = r.data;
							if (data.token) delete data.token;

							// session clear 진행 시 commonCodeList까지 없어지는 것 방지
							const commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));
							const loginUserInfo = JSON.parse(sessionStorage.getItem('loginUserInfo'));

							/**
							 * session storage 비우기 (session 데이터 계속 쌓이는것 방지)
							 * 뒤로가기 시 각 vue에서 main으로 이동되게 처리
							 * 뒤로가기 허용 시 sessionStorage.clear(); 삭제
							 *  */
							sessionStorage.clear();

							if (name == 'dashboard') {
								localStorage.clear();
								localStorage.setItem('loginUserInfo', JSON.stringify(loginUserInfo));
								localStorage.setItem(name + 'PageParam', JSON.stringify(data));
								window.open(data.viewUrl);
							} else {
								sessionStorage.setItem('commonCodeList', JSON.stringify(commonCodeList));
								sessionStorage.setItem('loginUserInfo', JSON.stringify(loginUserInfo));
								sessionStorage.setItem(name + 'PageParam', JSON.stringify(data));
								location.href = data.viewUrl;
							}
						}
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		},
		async getCommCodeList() {
			const commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));
			if (!commonCodeList || commonCodeList.length == 0) {
				await this.$axios
					.post(apiIndex.commCode.inqCommCodeAllList, {})
					.then(
						function (r) {
							sessionStorage.setItem('commonCodeList', JSON.stringify(r.data));
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			}
		},
		hasAuthority(urlList) {
			const auth = urlList.some(r => this.loginUserMenuList.includes(r));
			return auth;
		},
		logout() {
			// session storage 비우기
			sessionStorage.clear();
			// vue-cookies 제거
			VueCookies.remove('V2_ACCESS_TOKEN');
			location.href = '/login';
		},
	},
};
</script>
