var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "navbar-brand" }, [
      _vm.loginUserId != ""
        ? _c("a", { attrs: { href: _vm.mainUrl } }, [
            _c("img", {
              staticStyle: {
                height: "68px",
                "image-rendering": "-webkit-optimize-contrast"
              },
              attrs: { src: "/images/a-safe-logo.png", alt: "" }
            })
          ])
        : _vm._e(),
      _vm.loginUserId == ""
        ? _c("a", { attrs: { href: "/" } }, [
            _c("img", {
              staticStyle: {
                height: "68px",
                "image-rendering": "-webkit-optimize-contrast"
              },
              attrs: { src: "/images/a-safe-logo.png", alt: "" }
            })
          ])
        : _vm._e()
    ]),
    _c("div", { staticStyle: { clear: "both" }, attrs: { id: "topmenu" } }, [
      _c(
        "div",
        {
          staticClass: "navbar navbar-inverse",
          staticStyle: { border: "none", height: "48px" }
        },
        [
          _c(
            "div",
            {
              staticClass: "navbar-collapse collapse",
              staticStyle: { height: "48px !important" },
              attrs: { id: "navbar-mobile" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "nav navbar-nav navbar-menu",
                  staticStyle: { "margin-left": "40px" }
                },
                [
                  _vm._m(0),
                  _vm.menuGubun == "02"
                    ? _c("ul", [
                        _vm.hasAuthority(["/viewAccidentPrevent.do"])
                          ? _c("li", [
                              _c(
                                "a",
                                {
                                  attrs: { "data-desc": "재해예방조치" },
                                  on: {
                                    click: function($event) {
                                      return _vm.loadView(
                                        "accidentPrevent",
                                        $event
                                      )
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("재해예방조치")])]
                              )
                            ])
                          : _vm._e(),
                        _vm.hasAuthority([
                          "/viewDangerOccur.do",
                          "/viewWarnWorkOccur.do"
                        ])
                          ? _c("li", [
                              _vm._m(1),
                              _c("div", { staticClass: "hidden-ul" }, [
                                _c("ul", [
                                  _vm.hasAuthority(["/viewDangerOccur.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "위험발생이력"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "dangerOccur",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("위험발생이력")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewDangerOccur.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "위험발생이력"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "dangerOccurVideo",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("위험발생이력-비디오")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewWarnWorkOccur.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "주의작업이력"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "warnWorkOccur",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("주의작업이력")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewWarnWorkOccur.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "주의작업이력"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "warnWorkOccurVideo",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("주의작업이력-비디오")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            ])
                          : _vm._e(),
                        _vm.hasAuthority([
                          "/viewTree.do",
                          "/viewSiteStatistic.do",
                          "/viewAccidentStatistic.do",
                          "/viewObjectDangerOccurStatistic.do"
                        ])
                          ? _c("li", [
                              _vm._m(2),
                              _c("div", { staticClass: "hidden-ul" }, [
                                _c("ul", [
                                  _vm.hasAuthority(["/viewTree.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "현장별사용자조회"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "tree",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("현장별 사용자 조회")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewSiteStatistic.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "현장별통계"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "siteStatistic",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("현장별통계")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority([
                                    "/viewAccidentStatistic.do"
                                  ])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "재해유형별통계"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "accidentStatistic",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("재해유형별통계")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority([
                                    "/viewObjectDangerOccurStatistic.do"
                                  ])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "대상물별위험통계"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "objectDangerOccurStatistic",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("대상물별위험통계")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            ])
                          : _vm._e(),
                        _vm.hasAuthority(["/viewExemplaryCase.do"])
                          ? _c("li", [_vm._m(3)])
                          : _vm._e(),
                        _vm.hasAuthority([
                          "/viewNotice.do",
                          "/viewCompany.do",
                          "/viewSite.do",
                          "/viewUser.do",
                          "/viewMenu.do",
                          "/viewRoleGroup.do"
                        ])
                          ? _c("li", [
                              _vm._m(4),
                              _c("div", { staticClass: "hidden-ul" }, [
                                _c("ul", [
                                  _vm.hasAuthority(["/viewNotice.do"])
                                    ? _c("li", [_vm._m(5)])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewCompany.do"])
                                    ? _c("li", [_vm._m(6)])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewSite.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: { "data-desc": "현장관리" },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "site",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("현장관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewUser.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "사용자관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "user",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("사용자관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewMenu.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "메뉴코드관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "menu",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("메뉴코드관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewRoleGroup.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "권한그룹관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "roleGroup",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("권한그룹관리")])]
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            ])
                          : _vm._e(),
                        _vm.hasAuthority([
                          "/viewGrpCode.do",
                          "/viewCommCode.do",
                          "/viewObject.do",
                          "/viewTarget.do",
                          "/viewObjectTarget.do",
                          "/viewWarnWork.do",
                          "/viewCctv.do",
                          "/viewBasicAiConfig.do",
                          "/viewCctvAiConfig.do",
                          "/viewApiCallErrLog.do",
                          "/viewCctvDangerAreaConfig.do"
                        ])
                          ? _c("li", [
                              _vm._m(7),
                              _c("div", { staticClass: "hidden-ul" }, [
                                _c("ul", [
                                  _vm.hasAuthority(["/viewGrpCode.do"])
                                    ? _c("li", [_vm._m(8)])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewCommCode.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "공통코드관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "commCode",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("공통코드관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewObject.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "대상물관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "object",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("대상물관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewTarget.do"])
                                    ? _c("li", [_vm._m(9)])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewObjectTarget.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "대상물조치대상관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "objectTarget",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("대상물조치대상관리")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewWarnWork.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "위험대상물조합관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "warnWork",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("위험대상물조합관리")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewCctv.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: { "data-desc": "CCTV관리" },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "cctv",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("CCTV관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewCctvAiConfig.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "AICCTV설정"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "cctvAiConfig",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("AICCTV설정")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewBasicAiConfig.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "AI기본설정"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "basicAiConfig",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("AI기본설정")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewApiCallErrLog.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "API호출오류로그조회"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "apiCallErrLog",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("API호출오류로그조회")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority([
                                    "/viewCctvDangerAreaConfig.do"
                                  ])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "CCTV 위험영역 설정"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "cctvDangerAreaConfig",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("CCTV위험지역설정")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            ])
                          : _vm._e(),
                        _c("li", [
                          _c(
                            "a",
                            {
                              attrs: {
                                "data-desc": "dashboard",
                                target: "_blank"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.loadView("dashboard", $event)
                                }
                              }
                            },
                            [_c("span", [_vm._v("모니터링")])]
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.menuGubun == "01"
                    ? _c("ul", [
                        _vm.hasAuthority([
                          "/viewDangerOccur.do",
                          "/viewWarnWorkOccur.do"
                        ])
                          ? _c("li", [
                              _vm._m(10),
                              _c("div", { staticClass: "hidden-ul" }, [
                                _c("ul", [
                                  _vm.hasAuthority(["/viewDangerOccur.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "위험발생이력"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "dangerOccur",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("위험발생이력")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewDangerOccur.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "위험발생이력"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "dangerOccurVideo",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("위험발생이력-비디오")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewWarnWorkOccur.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "주의작업이력"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "warnWorkOccur",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("주의작업이력")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewWarnWorkOccur.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "주의작업이력"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "warnWorkOccurVideo",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("주의작업이력-비디오")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            ])
                          : _vm._e(),
                        _vm.hasAuthority([
                          "/viewTree.do",
                          "/viewObjectDangerOccurStatistic.do"
                        ])
                          ? _c("li", [
                              _vm._m(11),
                              _c("div", { staticClass: "hidden-ul" }, [
                                _c("ul", [
                                  _vm.hasAuthority(["/viewTree.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "현장별사용자조회"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "tree",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("현장별 사용자 조회")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority([
                                    "/viewObjectDangerOccurStatistic.do"
                                  ])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "대상물별위험통계"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "objectDangerOccurStatistic",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("대상물별위험통계")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            ])
                          : _vm._e(),
                        _vm.hasAuthority([
                          "/viewNotice.do",
                          "/viewCompany.do",
                          "/viewSite.do",
                          "/viewUser.do",
                          "/viewMenu.do",
                          "/viewRoleGroup.do"
                        ])
                          ? _c("li", [
                              _vm._m(12),
                              _c("div", { staticClass: "hidden-ul" }, [
                                _c("ul", [
                                  _vm.hasAuthority(["/viewNotice.do"])
                                    ? _c("li", [_vm._m(13)])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewCompany.do"])
                                    ? _c("li", [_vm._m(14)])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewSite.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: { "data-desc": "현장관리" },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "site",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("현장관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewUser.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "사용자관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "user",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("사용자관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewMenu.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "메뉴코드관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "menu",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("메뉴코드관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewRoleGroup.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "권한그룹관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "roleGroup",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("권한그룹관리")])]
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            ])
                          : _vm._e(),
                        _vm.hasAuthority([
                          "/viewGrpCode.do",
                          "/viewCommCode.do",
                          "/viewObject.do",
                          "/viewTarget.do",
                          "/viewObjectTarget.do",
                          "/viewWarnWork.do",
                          "/viewCctv.do",
                          "/viewBasicAiConfig.do",
                          "/viewCctvAiConfig.do",
                          "/viewApiCallErrLog.do",
                          "/viewCctvDangerAreaConfig.do"
                        ])
                          ? _c("li", [
                              _vm._m(15),
                              _c("div", { staticClass: "hidden-ul" }, [
                                _c("ul", [
                                  _vm.hasAuthority(["/viewGrpCode.do"])
                                    ? _c("li", [_vm._m(16)])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewCommCode.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "공통코드관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "commCode",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("공통코드관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewObject.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "대상물관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "object",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("대상물관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewTarget.do"])
                                    ? _c("li", [_vm._m(17)])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewObjectTarget.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "대상물조치대상관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "objectTarget",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("대상물조치대상관리")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewWarnWork.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "위험대상물조합관리"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "warnWork",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("위험대상물조합관리")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewCctv.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: { "data-desc": "CCTV관리" },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "cctv",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("CCTV관리")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewCctvAiConfig.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "AICCTV설정"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "cctvAiConfig",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("AICCTV설정")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewBasicAiConfig.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "AI기본설정"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "basicAiConfig",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_c("span", [_vm._v("AI기본설정")])]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority(["/viewApiCallErrLog.do"])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "API호출오류로그조회"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "apiCallErrLog",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("API호출오류로그조회")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasAuthority([
                                    "/viewCctvDangerAreaConfig.do"
                                  ])
                                    ? _c("li", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              "data-desc": "CCTV 위험영역 설정"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadView(
                                                  "cctvDangerAreaConfig",
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("CCTV위험지역설정")
                                            ])
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            ])
                          : _vm._e(),
                        _c("li", [
                          _c(
                            "a",
                            {
                              attrs: {
                                "data-desc": "dashboard",
                                target: "_blank"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.loadView("dashboard", $event)
                                }
                              }
                            },
                            [_c("span", [_vm._v("모니터링")])]
                          )
                        ])
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "ul",
                {
                  staticClass: "nav navbar-nav navbar-right",
                  staticStyle: { height: "48px" }
                },
                [
                  _vm.loginUserId != ""
                    ? _c("li", { staticClass: "dropdown dropdown-user" }, [
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-toggle",
                            attrs: {
                              "data-toggle": "dropdown",
                              "aria-expanded": "true"
                            }
                          },
                          [
                            _c("i", { staticClass: "icon-user-tie" }),
                            _c("span", [_vm._v(_vm._s(_vm.loginUserId))]),
                            _c("i", { staticClass: "caret" })
                          ]
                        ),
                        _c(
                          "ul",
                          { staticClass: "dropdown-menu dropdown-menu-right" },
                          [
                            _c("li", [
                              _c("a", { on: { click: _vm.logout } }, [
                                _c("i", { staticClass: "icon-switch2" }),
                                _vm._v(" 로그아웃 ")
                              ])
                            ])
                          ]
                        )
                      ])
                    : _c("li", { staticClass: "dropdown dropdown-user" }, [
                        _vm._m(18),
                        _vm._m(19)
                      ])
                ]
              )
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("i", { staticClass: "icon-paragraph-justify3" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [
      _c("span", [_vm._v("위험/주의발생이력")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [
      _c("span", [_vm._v("조회/통계")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { href: "/exemplary-case", "data-desc": "모범사례" } },
      [_c("span", [_vm._v("모범사례")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [_c("span", [_vm._v("조직관리")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "/notice", "data-desc": "공지사항" } }, [
      _c("span", [_vm._v("공지사항")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "/company", "data-desc": "업체관리" } }, [
      _c("span", [_vm._v("업체관리")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [
      _c("span", [_vm._v("AI/데이터관리")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { href: "/group-code", "data-desc": "그룹코드관리" } },
      [_c("span", [_vm._v("그룹코드관리")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { href: "/target", "data-desc": "조치대상관리" } },
      [_c("span", [_vm._v("조치대상관리")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [
      _c("span", [_vm._v("위험발생이력")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [
      _c("span", [_vm._v("조회/통계")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [_c("span", [_vm._v("조직관리")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "/notice", "data-desc": "공지사항" } }, [
      _c("span", [_vm._v("공지사항")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "/company", "data-desc": "업체관리" } }, [
      _c("span", [_vm._v("업체관리")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [
      _c("span", [_vm._v("AI/데이터관리")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { href: "/group-code", "data-desc": "그룹코드관리" } },
      [_c("span", [_vm._v("그룹코드관리")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { href: "/target", "data-desc": "조치대상관리" } },
      [_c("span", [_vm._v("조치대상관리")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "dropdown-toggle",
        attrs: { "data-toggle": "dropdown", "aria-expanded": "true" }
      },
      [
        _c("i", { staticClass: "icon-user-tie" }),
        _c("span", [_vm._v("Anonymous")]),
        _c("i", { staticClass: "caret" })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "dropdown-menu dropdown-menu-right" }, [
      _c("li", [
        _c("a", { attrs: { href: "/login" } }, [
          _c("i", { staticClass: "icon-switch2" }),
          _vm._v(" 로그인 ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }